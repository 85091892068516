import './scents.scss';
import {Title} from '../../shared/ui/title/title';
import {Scent} from '../../shared/models/scent';
import React, {useState} from 'react';
import {Button} from '../../shared/ui/button/button';
import {ButtonStyle} from '../../shared/enums/button-style';

import {ReactSVG} from 'react-svg';
import {scentsCategories, scentsData} from './scents-data';
import {scrollTo} from '../../shared/helpers/scroll-to';
import {MenuLinkId} from '../../shared/enums/menu-link-id';

export const ScentsMobile = () => {
    const title: string = 'Zapachy';
    const scentCategories = scentsCategories;
    const scents: Scent[] = scentsData;
    const [activeCategories, setActiveCategories] = useState([scentCategories[0]]);

    function setActiveCategory(scentCategory: any) {
        if (activeCategories.includes(scentCategory)) {
            setActiveCategories(prevState => prevState.filter(category => category !== scentCategory));
        } else {
            setActiveCategories(prevState => [...prevState, scentCategory]);
        }
    }
    function isCategoryActive(scentCategory: any) {
        return activeCategories.includes(scentCategory);
    }

    return (
        <div className="scents" id="scents">
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col-lg-10">
                        <div className="card">
                            <Title text={title}/>
                            <div className="row justify-content-md-center">
                                <div className="col-lg-8">
                                    <div className="scents__category">
                                        {scentCategories.map((scentCategory, index: number) => {
                                            return <div className="scents__category-single" key={index}>
                                                <Button
                                                    label={scentCategory.name}
                                                    customClass={isCategoryActive(scentCategory.id) ? 'active' : ''}
                                                    buttonStyle={ButtonStyle.Tab}
                                                    handleOnClick={() => setActiveCategory(scentCategory.id)}>
                                                    <ReactSVG wrapper="span" src={scentCategory.icon}/>
                                                </Button>
                                                <div className="row">
                                                    {scents.map((scent: Scent, scentIndex: number) => {
                                                        return <div key={scentIndex}>
                                                            {scent.category === scentCategory.id &&
                                                                isCategoryActive(scentCategory.id) &&
                                                                <div className="col-lg-4">
                                                                    <div className="scents__single">
                                                                        {scent.name}
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    })}
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    <div className="row justify-content-md-center">
                                        <div className="col-lg-10 rules__button">
                                            <a className="btn btn-outline--brown"
                                               onClick={() => scrollTo(MenuLinkId.Contact)}>Zamów świecę</a>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center">
                                        <div className="col-lg-10 rules__button">
                                            <p>Jeśli na liście nie ma zapachu którego szukasz lub masz swoj pomysł na
                                                zapachową mieszkankę - napisz do mnie.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
