import './banner.scss';
import React from 'react';
// @ts-ignore
import video from '../../../assets/images/banner-video.mov';
import {scrollTo} from '../../helpers/scroll-to';
import {MenuLinkId} from '../../enums/menu-link-id';

export const Banner = () => {
    return (
        <div className="banner">
            <div className="banner__video-background"/>
            <video className="banner__video" autoPlay loop muted>
                <source src={video} type="video/mp4"/>
            </video>
            <div className="banner__title-wrapper">
                <p className="banner__subtitle">naturalne i ekologiczne</p>
                <h1 className="banner__title">świece sojowe dla Ciebie</h1>
                <a className="btn btn-outline--white"
                   onClick={() => scrollTo(MenuLinkId.Scents)}>Zobacz dostępne zapachy</a>
            </div>
        </div>
    );
}
