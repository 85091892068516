import './header.scss';
import logo from '../../../assets/images/ferlo-logo.svg';
import {MenuLink} from '../../models/menu-link';
import {MenuLinkId} from '../../enums/menu-link-id';
import {useEffect, useState} from 'react';
import background from '../../../assets/images/menu-bg.png';
import {scrollTo} from '../../helpers/scroll-to';

export const Header = () => {
    const menu: MenuLink[] = [
        {
            label: 'Oferta',
            id: MenuLinkId.Offer,
        },
        {
            label: 'Zasady palenia',
            id: MenuLinkId.Rules,
        },
        {
            label: 'O Ferlo',
            id: MenuLinkId.AboutUs,
        },
        {
            label: 'Zapachy',
            id: MenuLinkId.Scents,
        },
        {
            label: 'Kontakt',
            id: MenuLinkId.Contact,
        }
    ];
    const [activeLink, setActiveLink] = useState<MenuLinkId | undefined>(undefined);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        scrollTo(activeLink);
    }, [activeLink]);

    useEffect(() => {
        window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 10);
        });
    });

    const scrollToSection = () => {
        scrollTo(MenuLinkId.Contact);
        setIsMenuOpen(false);
    }

    return (
        <>
            <div className={`header ${isMenuOpen ? 'is-active' : ''} ${scroll ? 'sticky' : ''}`}
                 style={{
                     backgroundImage: `${isMenuOpen ? `url(${background})` : ''}`,
                 }}>
                <figure className="logo">
                    <img src={logo}/>
                </figure>
                <div className="header__links">
                    <nav role="navigation">
                        {menu.map((link: MenuLink, index: number) =>
                            <a onClick={(e) => {
                                e.preventDefault();
                                setActiveLink(link.id)
                                setIsMenuOpen(false);
                            }}
                               key={index}>
                                {link.label}
                            </a>
                        )}
                    </nav>
                </div>
                <a className="btn btn-primary" onClick={() => scrollToSection()}>Zamów świecę</a>
            </div>
            <div className={`header header--mobile ${isMenuOpen ? 'is-active' : ''} ${scroll ? 'sticky' : ''}`}>
                <figure className="logo">
                    <img src={logo}/>
                </figure>
                <button className={`hamburger hamburger--squeeze ${isMenuOpen ? 'is-active' : ''}`} type="button"
                        onClick={(e => {
                            e.preventDefault();
                            setIsMenuOpen(!isMenuOpen);
                        })}>
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
            </div>
        </>
    );
}
