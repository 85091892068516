import React, {useEffect} from 'react';
import './App.scss';
import {Header} from './shared/ui/header/header';
import {Layout} from './shared/ui/layout/layout';
import {Banner} from './shared/ui/banner/banner';

function App() {

    useEffect(() => {
        document.title = 'Ferlo - Świece sojowe';
    }, []);

    return (
        <div className="App">
            <Header/>
            <Banner/>
            <Layout/>
        </div>
    );
}

export default App;
