import {ScentCategory} from '../../shared/enums/scent-category';
import fruit from '../../assets/images/icons/fruit.svg';
import dry from '../../assets/images/icons/dry.svg';
import fresh from '../../assets/images/icons/fresh.svg';
import sweet from '../../assets/images/icons/chocolate.svg';
import winter from '../../assets/images/icons/winter.svg';

export const scentsCategories = [
  {
    name: 'Zimowe',
    id: ScentCategory.Winter,
    icon: winter,
  },
  {
    name: 'Owocowe',
    id: ScentCategory.Fruit,
    icon: fruit,
  },
  {
    name: 'Czekoladowe i słodkie',
    id: ScentCategory.Sweet,
    icon: sweet,
  },
  {
    name: 'Wytrawne',
    id: ScentCategory.Dry,
    icon: dry,
  },
  {
    name: 'Świeże',
    id: ScentCategory.Fresh,
    icon: fresh,
  },
];
export const scentsData = [
  {
    name: 'Cynamon',
    category: ScentCategory.Winter,
  },
  {
    name: 'Dynia-goździk',
    category: ScentCategory.Winter,
  },
  {
    name: 'Żurawina',
    category: ScentCategory.Winter,
  },
  {
    name: 'Pomarańcza-cynamon',
    category: ScentCategory.Winter,
  },
  {
    name: 'Burysztyn',
    category: ScentCategory.Winter,
  },
  {
    name: 'Piernik',
    category: ScentCategory.Winter,
  },
  {
    name: 'Imbir-cytryna',
    category: ScentCategory.Winter,
  },
  {
    name: 'Pieczone jabłko',
    category: ScentCategory.Winter,
  },
  {
    name: 'Pomarańcza-imbir',
    category: ScentCategory.Winter,
  },
  {
    name: 'Tarta żurawinowa',
    category: ScentCategory.Winter,
  },
  {
    name: 'Orzech laskowy',
    category: ScentCategory.Winter,
  },
  {
    name: 'Karmel',
    category: ScentCategory.Winter,
  },
  {
    name: 'Pomarańcza',
    category: ScentCategory.Winter,
  },
  {
    name: 'Cozy home',
    category: ScentCategory.Winter,
  },
  {
    name: 'Miód',
    category: ScentCategory.Winter,
  },
  {
    name: 'Imbir',
    category: ScentCategory.Winter,
  },
  {
    name: 'Czekolada',
    category: ScentCategory.Winter,
  },
  {
    name: 'Jabłko-cynamon',
    category: ScentCategory.Winter,
  },
  {
    name: 'Dżem żurawinowy',
    category: ScentCategory.Winter,
  },
  {
    name: 'Malina',
    category: ScentCategory.Fruit,
  },
  {
    name: 'Malina-wanilia',
    category: ScentCategory.Fruit,
  },
  {
    name: 'Wiśnia-wanilia',
    category: ScentCategory.Fruit,
  },
  {
    name: 'Cytryna',
    category: ScentCategory.Fruit,
  },
  {
    name: 'Truskawka',
    category: ScentCategory.Fruit,
  },
  {
    name: 'Gruszka',
    category: ScentCategory.Fruit,
  },
  {
    name: 'Owoce lasu',
    category: ScentCategory.Fruit,
  },
  {
    name: 'Wiśnia-czekolada',
    category: ScentCategory.Fruit,
  },
  {
    name: 'Arbuz',
    category: ScentCategory.Fruit,
  },
  {
    name: 'Wiśnia',
    category: ScentCategory.Fruit,
  },
  {
    name: 'Zielone jabłko',
    category: ScentCategory.Fruit,
  },
  {
    name: 'Mango',
    category: ScentCategory.Fruit,
  },
  {
    name: 'Melon',
    category: ScentCategory.Fruit,
  },
  {
    name: 'Granat',
    category: ScentCategory.Fruit,
  },
  {
    name: 'Kokos',
    category: ScentCategory.Fruit,
  },
  {
    name: 'Brownie',
    category: ScentCategory.Sweet,
  },
  {
    name: 'Czekolada',
    category: ScentCategory.Sweet,
  },
  {
    name: 'Biała czekolada',
    category: ScentCategory.Sweet,
  },
  {
    name: 'Wiśnia-czekolada',
    category: ScentCategory.Sweet,
  },
  {
    name: 'Czekolada-kokos',
    category: ScentCategory.Sweet,
  },
  {
    name: 'Kawa-czekolada',
    category: ScentCategory.Sweet,
  },
  {
    name: 'Delicje',
    category: ScentCategory.Sweet,
  },
  {
    name: 'Wanilia',
    category: ScentCategory.Sweet,
  },
  {
    name: 'Kokos',
    category: ScentCategory.Sweet,
  },
  {
    name: 'Palony cukier',
    category: ScentCategory.Sweet,
  },
  {
    name: 'Domowe ciasteczka',
    category: ScentCategory.Sweet,
  },
  {
    name: 'Drzewo sandałowe',
    category: ScentCategory.Dry,
  },
  {
    name: 'Palo santo',
    category: ScentCategory.Dry,
  },
  {
    name: 'Paczuli',
    category: ScentCategory.Dry,
  },
  {
    name: 'Kawa',
    category: ScentCategory.Dry,
  },
  {
    name: 'Kadzidełko',
    category: ScentCategory.Dry,
  },
  {
    name: 'Erotyk',
    category: ScentCategory.Dry,
  },
  {
    name: 'Afrodyzjak',
    category: ScentCategory.Dry,
  },
  {
    name: 'Drzewo tekowe',
    category: ScentCategory.Dry,
  },
  {
    name: 'Piżmo',
    category: ScentCategory.Dry,
  },
  {
    name: 'Drzewo cedrowe',
    category: ScentCategory.Dry,
  },
  {
    name: 'Vetiver',
    category: ScentCategory.Dry,
  },
  {
    name: 'Darshan',
    category: ScentCategory.Dry,
  },
  {
    name: 'Lemoniada',
    category: ScentCategory.Fresh,
  },
  {
    name: 'Lemoniada malinowa',
    category: ScentCategory.Fresh,
  },
  {
    name: 'Mięta',
    category: ScentCategory.Fresh,
  },
  {
    name: 'Bambus-limonka',
    category: ScentCategory.Fresh,
  },
  {
    name: 'Eukaliptus',
    category: ScentCategory.Fresh,
  },
  {
    name: 'Zielona herbata',
    category: ScentCategory.Fresh,
  },
  {
    name: 'Trawa cytrynowa',
    category: ScentCategory.Fresh,
  },
  {
    name: 'Bawełna',
    category: ScentCategory.Fresh,
  },
  {
    name: 'Trawa cytrynowa-zielona herbata',
    category: ScentCategory.Fresh,
  },
];
