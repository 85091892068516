import './contact-form.scss';
import {Title} from '../../shared/ui/title/title';
import {ButtonStyle} from '../../shared/enums/button-style';
import {Button} from '../../shared/ui/button/button';
import {Divider} from '../../shared/ui/divider/divider';
import React, {useRef, useState} from 'react';
import {scrollTo} from '../../shared/helpers/scroll-to';
import {MenuLinkId} from '../../shared/enums/menu-link-id';
import Axios from 'axios';
import Toast from '../../shared/ui/toast/toast';
import {ToastItem} from '../../shared/models/toast-props';
// @ts-ignore
import ReCAPTCHA from 'react-google-recaptcha';

const sendEmailURL = 'https://us-central1-ferlo-landing.cloudfunctions.net/sendEmail';
const checkTokenURL = 'https://us-central1-ferlo-landing.cloudfunctions.net/checkToken';


export const ContactForm = (props: { isMobile: any }) => {

    const [contactForm, setContactForm] = useState({
        message: '',
        email: '',
        name: '',
        subject: 'Ferlo: Masz nową wiadomość',
    });


    const {message, email, name, subject} = contactForm;

    const [notification, setNotification] = useState<ToastItem[]>([])
    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)

    let captchaRef = useRef<ReCAPTCHA>(null)

    // @ts-ignore
    const handleState = ({target: {id, value}}) => {
        return setContactForm({...contactForm, [id]: value});
    }


    const sendEmail = () => {
        setIsFormSubmitted(true);
        if (!!message && !!email && !!name && !!captchaRef) {
            // @ts-ignore
            const token = captchaRef?.current?.getValue();
            Axios.get(checkTokenURL, {
                    params: {
                        token,
                    }
                },
            )
                .then((res: any) => {
                    if (res.data.success) {
                        Axios.get(sendEmailURL, {
                            params: {
                                message,
                                email,
                                name,
                                subject,
                            },
                        }).then(() => {
                            setNotification([{
                                id: 'contact-form',
                                title: 'Wiadomość wysłana',
                                description: 'Twoja wiadomość została wysłana.',
                                backgroundColor: '#F7F2EE',
                            }])
                        })
                        setIsFormSubmitted(false);
                        setContactForm({
                            message: '',
                            email: '',
                            name: '',
                            subject: 'Ferlo: Masz nową wiadomość',
                        });
                        (document.getElementById('form') as HTMLFormElement)?.reset();
                        captchaRef.current?.reset();
                    } else {
                        setNotification([{
                            id: 'contact-form',
                            title: 'Ups!',
                            description: 'Coś poszło nie tak. Spróbuj ponownie',
                            backgroundColor: '#d02020',
                        }])
                    }
                })
                .catch((error: any) => {
                    console.log(error);
                })
        }
    };

    return (
        <div className="contact-form" id="contact">
            <Toast
                toastList={notification}
                position="bottom-right"
            />
            <Divider/>
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className={`col-lg-5 ${props.isMobile ? 'order-2' : ''}`}>
                        <Title text="Skontaktuj się"/>
                        <p className="paragraph paragraph--medium paragraph--left">
                            <b>
                                Jeśli masz jakieś pytania, chcesz zamówić świeczki, dowiedzieć się więcej lub masz
                                niestandardowe zamówienie - napisz i zajmę się tym :)
                            </b>
                        </p>
                        <Divider/>
                        <p className="paragraph paragraph--medium paragraph--left">
                            <b>Ferlo Adrianna Płatek</b>
                        </p>
                        <p className="paragraph paragraph--left">
                            Wrocław
                        </p>
                    </div>
                    <div className={`col-lg-5 ${props.isMobile ? 'order-1' : ''}`}>
                        <div className="card contact-form__card">
                            <Title text="Zamów świecę"/>
                            <form id="form">
                                <div className="input__wrapper">
                                    <input id={'name'}
                                           className={`form-control ${isFormSubmitted && !name ? 'invalid' : ''}`}
                                           placeholder="Imię"
                                           name="name"
                                           onChange={handleState}/>
                                </div>
                                <div className="input__wrapper">
                                    <input id={'email'}
                                           type="email"
                                           placeholder="Adres e-mail"
                                           className={`form-control ${isFormSubmitted && !email ? 'invalid' : ''}`}
                                           name="email"
                                           onChange={handleState}/>
                                </div>
                                <div className="input__wrapper">
                                    <textarea id={'message'}
                                              className={`${isFormSubmitted && !message ? 'invalid' : ''}`}
                                              name="message"
                                              placeholder="Treść wiadomości"
                                              onChange={handleState}/>
                                </div>
                                <p className="contact-form__scents">
                                    Nie pamiętasz zapachu? <a onClick={() => scrollTo(MenuLinkId.Scents)}>Otwórz
                                    ściągę</a>
                                </p>
                                <p className="paragraph paragraph--small paragraph--left">
                                    Administrator na podstawie wyrażonej zgody przetwarza Twoje dane osobowe tylko i
                                    wyłącznie w celu udzielenia odpowiedzi na przesłaną przez Ciebie wiadomość za
                                    pośrednictwm formularza kontaktowego oraz w celu prowadzenia ewentualnej dalszej
                                    korespondencji w tym przedmiocie.
                                </p>
                                <ReCAPTCHA
                                    ref={captchaRef}
                                    sitekey={'6LfISugkAAAAAN1mCUImVnIt58DthMLfH9cMzQsU'}
                                />
                                <Button
                                    label="Wyślij wiadomość"
                                    buttonStyle={ButtonStyle.Secondary}
                                    buttonType="submit"
                                    handleOnClick={() => sendEmail()}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
