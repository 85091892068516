import './description.scss';
import {TextImage} from '../../shared/ui/text-image/text-image';
import {ImagePosition} from '../../shared/enums/position';
import {TextImageProps} from '../../shared/models/text-image';
import {Title} from '../../shared/ui/title/title';
import cozyHome from '../../assets/images/description-cozy-home.png';
import scents from '../../assets/images/scents-ferdek.png';
import React from 'react';
import {Divider} from '../../shared/ui/divider/divider';
import {scrollTo} from '../../shared/helpers/scroll-to';
import {MenuLinkId} from '../../shared/enums/menu-link-id';

export const Description = () => {

    const title: string = 'Nasza oferta';

    const textImages: TextImageProps[] = [
        {
            children: <div className="description__paragraph">
                <p className="paragraph">
                    Moim celem jest dostarczanie pięknych, naturalnych i ekologicznych świec, które będą cieszyć oko i
                    duszę. Każda świeczka zamknięta jest w brązowym słoiku z zakrętką, co zapobiega ulatnianiu się
                    zapachu,
                    a po spaleniu świecy słoik może być wykorzystany lub oddany do recyklingu.
                </p>
                <p className="paragraph">
                    Dostępne świece:<br/>
                    - Wielkość: <b>60ml</b>, <b>120ml</b> i <b>180ml</b><br/>
                    - Knoty: <b>bawełniany</b> lub <b>drewniany</b><br/>
                    - Wosk: <b>sojowy</b> lub <b>rzepakowy</b>
                </p>
                <p className="paragraph">
                    Jeśli potrzebujesz większej ilości świec, np. na wesele lub jako prezent dla większej grupy, mogę
                    przygotować je również <b>w pojemności 30ml</b>. Jeśli szukasz innej pojemności lub zapachu,
                    skontaktuj się z mną.
                </p>
                <a className="btn btn-outline--brown" onClick={() => scrollTo(MenuLinkId.Scents)}>Sprawdź zapachy</a>
            </div>,
            imageSrc: cozyHome
        },
        {
            children: <div className="description__paragraph">
                <Title text=" Wosk sojowy czy rzepakowy?"/>
                <p className="paragraph">
                Gdy przychodzi czas na wybór idealnej świece, warto zastanowić się nad różnymi opcjami. Zarówno wosk sojowy, jak i rzepakowy oferują wiele korzyści dla Ciebie i dla środowiska.
                </p>
                <p className="paragraph">
                Oba woski są wykonane z naturalnych składników roślinnych, co oznacza, że są ekologiczne i bezpieczne dla zdrowia. Zarówno wosk sojowy, jak i rzepakowy charakteryzują się niską emisją toksycznych substancji, co sprawia, że ​​są one bardziej przyjazne dla środowiska i zdrowsze dla Ciebie.
                </p>
                <p className="paragraph">
                Ponadto oba woski mają długi czas palenia i zapewniają równomierne rozpraszanie zapachu, co sprawia, że ​​twój dom wypełnia się przyjemnymi aromatami na długi czas.
                </p>
                <p className="paragraph">
                    <b>
                    Jednak wosk rzepakowy ma dodatkową zaletę - jest produkowany w Unii Europejskiej, co oznacza mniejszy ślad węglowy. Wybierając świece z wosku rzepakowego, wspierasz lokalnych producentów i przyczyniasz się do ochrony naszej planety.
                   </b>
                </p>
                <p className="paragraph">
                Nie ważne, czy wybierzesz wosk sojowy czy rzepakowy, możesz być pewien, że twój wybór będzie korzystny zarówno dla Ciebie, jak i dla środowiska. Przekonaj się sam, jak przyjemne mogą być chwile spędzone przy świetle ekologicznych świec!
                   </p>
            </div>,
            imageSrc: scents
        }
    ];

    return (
        <div className="description" id="offer">
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col-lg-6">
                        <Title text={title}/>
                        <p className="paragraph paragraph--medium paragraph--center">
                            <b>
                                Oferta Ferlo jest różnorodna - od delikatnych kwiatowych zapachów, po ciepłe i korzenne
                                - na pewno znajdziesz coś dla siebie.
                            </b>
                        </p>
                    </div>
                </div>
            </div>
            {textImages.map((item: TextImageProps, index: number) => {
                return <>
                    <TextImage imagePosition={index % 2 == 0 ? ImagePosition.Left : ImagePosition.Right}
                               imageSrc={item.imageSrc}
                               key={index}>
                        {item.children}
                    </TextImage>
                    <Divider/>
                </>
            })}
        </div>
    );
}
