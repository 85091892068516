import './scents.scss';
import {Title} from '../../shared/ui/title/title';
import {Scent} from '../../shared/models/scent';
import {ScentCategory} from '../../shared/enums/scent-category';
import React, {useEffect, useState} from 'react';
import {Button} from '../../shared/ui/button/button';
import {ButtonStyle} from '../../shared/enums/button-style';
import {ReactSVG} from 'react-svg';
import {scentsCategories, scentsData} from './scents-data';
import {scrollTo} from '../../shared/helpers/scroll-to';
import {MenuLinkId} from '../../shared/enums/menu-link-id';

export const Scents = () => {
    const title: string = 'Zapachy';
    const scentCategories = scentsCategories;
    const scents: Scent[] = scentsData;
    const [scentsToDisplay, setScentsToDisplay] = useState<Scent[]>([]);
    const [activeTab, setActiveTab] = useState<ScentCategory>(ScentCategory.Winter);

    useEffect(() => {
        setScentsToDisplay(scents.filter(scent => scent.category === activeTab));
    }, [activeTab]);

    function changeTab(scentCategory: ScentCategory) {
        setActiveTab(scentCategory);
    }

    return (
        <div className="scents" id="scents">
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col-lg-10">
                        <div className="card">
                            <Title text={title}/>
                            <div className="row justify-content-md-center">
                                <div className="col-lg-8">
                                    <div className="scents__category">
                                        {scentCategories.map((scentCategory, index: number) => {
                                            return <Button
                                                label={scentCategory.name}
                                                customClass={activeTab === scentCategory.id ? 'active' : ''}
                                                key={index}
                                                buttonStyle={ButtonStyle.Tab}
                                                handleOnClick={() => changeTab(scentCategory.id)}>
                                                <ReactSVG wrapper="span" src={scentCategory.icon}/>
                                            </Button>
                                        })}
                                    </div>
                                    <div className="row">
                                        {scentsToDisplay.map((scent: Scent, index: number) => {
                                            return <div className="col-lg-4" key={index}>
                                                <div className="scents__single">
                                                    {scent.name}
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    <div className="row justify-content-md-center">
                                        <div className="col-lg-10 rules__button">
                                            <a className="btn btn-outline--brown"
                                               onClick={() => scrollTo(MenuLinkId.Contact)}>Zamów świecę</a>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center">
                                        <div className="col-lg-10 rules__button">
                                            <p>Jeśli na liście nie ma zapachu którego szukasz lub masz swoj pomysł na
                                                zapachową mieszkankę - napisz do mnie.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
