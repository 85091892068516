import './about-us.scss';
import {Title} from '../../shared/ui/title/title';
import cats from '../../assets/images/lola-ferdek.png';
import {TextImageProps} from '../../shared/models/text-image';
import React from 'react';
import {TextImage} from '../../shared/ui/text-image/text-image';
import {ImagePosition} from '../../shared/enums/position';
import {Divider} from '../../shared/ui/divider/divider';

export const AboutUs = () => {
    const title: string = 'Czym jest Ferlo?';
    const textImages: TextImageProps[] = [
        {
            children: <div className="description__paragraph">
                <p className="paragraph">
                    Moja misja to tworzenie pięknych i ekologicznych świec, które pochodzą z naturalnych składników.
                    Świeczki Ferlo są całkowicie ekologiczne i nietoksyczne, dzięki czemu bezpiecznego dla Twojego domu,
                    zwierząt i środowiska. Wszystkie produkty są również w 100% wegańskie.
                </p>
                <p className="paragraph">
                    <b>
                        Ferlo, czyli dwa koty - Ferdek i Lola, są nie tylko moimi ukochanymi zwierzakami, ale również
                        inspiracją. Pierwsze świeczki w dużej ilości, zrobiłam właśnie na Bazarek (licytacje) dla
                        fundacji, której celem jest pomoc potrzebującym kotom oraz wsparcie domów tymczasowych. I
                        oczywiście bierzemy udział w kolejnych edycjach Bazarku.
                    </b>
                </p>
                <p className="paragraph">
                    Cieszę się, że mogłam przyłożyć swoją cegiełkę do tej szlachetnej sprawy. Chcę, aby świeczki Ferlo
                    były nie tylko piękne, ale również przynosiły korzyści innym.
                </p>
                <p className="paragraph">
                    Świece Ferlo to nie tylko piękne i naturalne źródło światła, ale także doskonały sposób na ochronę
                    środowiska. Soja jest rośliną niskoemisyjną i biodegradowalną, dlatego nasze świeczki są przyjazne
                    dla środowiska.
                </p>
            </div>,
            imageSrc: cats
        }
    ]
    return (
        <div className="about-us" id="about-us">
            <Divider/>
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col-lg-6">
                        <Title text={title}/>
                        <p className="paragraph paragraph--medium paragraph--center">
                            <b>
                                Ferlo to firma powstała z pasji i miłości do natury. Wszystkie świeczki sojowe robię
                                ręcznie w domowej kuchni, dzięki czemu każda z nich jest wyjątkowa i unikatowa.
                            </b>
                        </p>
                    </div>
                </div>
            </div>
            {textImages.map((item: TextImageProps, index: number) => {
                return <div key={index}>
                    <TextImage imagePosition={index % 2 == 0 ? ImagePosition.Left : ImagePosition.Right}
                               imageSrc={item.imageSrc}>
                        {item.children}
                    </TextImage>
                    <Divider/>
                </div>
            })}
        </div>
    );
}
