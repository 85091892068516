import './gallery.scss';
import {Title} from '../../shared/ui/title/title';
import React, {useState} from 'react';

import {Divider} from '../../shared/ui/divider/divider';
import image1 from '../../assets/images/gallery/image-1.png';
import image2 from '../../assets/images/gallery/image-2.png';
import image3 from '../../assets/images/gallery/image-3.png';
import image4 from '../../assets/images/gallery/image-4.png';
import image5 from '../../assets/images/gallery/image-5.png';
import image6 from '../../assets/images/gallery/image-6.png';
import image7 from '../../assets/images/gallery/image-7.png';
import image8 from '../../assets/images/gallery/image-8.png';
import image9 from '../../assets/images/gallery/image-9.png';
import image10 from '../../assets/images/gallery/image-10.png';
import image11 from '../../assets/images/gallery/image-11.png';
import image12 from '../../assets/images/gallery/image-12.png';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import {TextImageProps} from '../../shared/models/text-image';


export const Gallery = () => {
    const title: string = 'Galeria świec';
    const [images, setImages] = useState([
        {
            src: image1,
            thumbnail: image1
        },
        {
            src: image2,
            thumbnail: image2
        },
        {
            src: image3,
            thumbnail: image3
        },
        {
            src: image4,
            thumbnail: image4
        },
        {
            src: image5,
            thumbnail: image5
        },
        {
            src: image6,
            thumbnail: image6
        },
        {
            src: image7,
            thumbnail: image7
        },
        {
            src: image8,
            thumbnail: image8
        },
        {
            src: image9,
            thumbnail: image9
        },
        {
            src: image10,
            thumbnail: image10
        },
        {
            src: image11,
            thumbnail: image11
        },
        {
            src: image12,
            thumbnail: image12
        },
    ]);
    const [isGalleryOpen, setIsGalleryOpen] = useState<boolean>(false);
    const [swiperInstance, setSwiperInstance] = useState<any>(null);
    const [swiperLightboxInstance, setSwiperLightboxInstance] = useState<any>(null);


    const onSlideClick = (e:any) => {
        setIsGalleryOpen(true);
        const index = Number(swiperInstance.clickedSlide.swiperSlideIndex);
        console.log(index)

        const slideTo = index <= 10 ? index + 1 : index;
        swiperLightboxInstance.slideToLoop(index, 0);   
        console.log(swiperLightboxInstance.activeIndex)
    }

    return (
        <div className="gallery" id="gallery">
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col-lg-6">
                        <Title text={title}/>
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-lg-12">
                        <Swiper
                            loop={true}
                            navigation={true} modules={[Navigation]}
                            slidesPerView={5}
                            spaceBetween={32}
                            centeredSlides={true}
                            onInit={(swiper) => setSwiperInstance(swiper)}
                            breakpoints={{
                                100: {
                                    slidesPerView: 'auto',
                                    spaceBetween: 16,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 16,
                                },
                                1200: {
                                    slidesPerView: 5,
                                    spaceBetween: 32,
                                },
                            }}
                        >
                            {images.map((image, index: number) => {

                                return <SwiperSlide onClick={(e) => {onSlideClick(e)}}>
                                    <figure className="gallery__image">
                                        <img src={image.src}/>
                                    </figure>
                                </SwiperSlide>
                            })}
                        </Swiper>
                        <button className={`hamburger hamburger--squeeze is-active ${isGalleryOpen ? '' : 'hidden'}`} type="button"
                        onClick={(e => {
                            e.preventDefault();
                            setIsGalleryOpen(false);
                        })}>
                  <span className={`hamburger-box`}>
                    <span className="hamburger-inner"></span>
                  </span>
                  </button>
                  <Swiper
                  className={`swiper-lightbox ${isGalleryOpen ? '' : 'hidden'}`}
                      loop={true}
                      navigation={true} modules={[Navigation]}
                      slidesPerView={1}
                      centeredSlides={true}
                      onInit={(swiper) => setSwiperLightboxInstance(swiper)}
                  >
                      {images.map((image, index: number) => {

                          return <SwiperSlide>
                              <figure className="gallery__image gallery__image--big">
                                  <img src={image.src}/>
                              </figure>
                          </SwiperSlide>
                      })}
                  </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
}
