import './button.scss';
import {ButtonProps} from '../../models/button-props';
import React from 'react';

export const Button = (props: ButtonProps) => {
    const {buttonType = "button"} = props;

    function handleOnClick(e: React.MouseEvent<HTMLElement>) {
        e.preventDefault();
        props.handleOnClick();
    }

    return (
        <button
            disabled={props.disabled}
            type={buttonType}
            className={`btn btn-${props.buttonStyle} ${props.customClass}`}
            onClick={(e) => handleOnClick(e)}>
            {props.children}
            {props.label}
        </button>
    );
}
