import './rules.scss';
import React from 'react';
import {Title} from '../../shared/ui/title/title';
import {Rule} from '../../shared/models/rule';
import degrees from '../../assets/images/icons/degrees.svg';
import baby from '../../assets/images/icons/baby.svg';
import clock from '../../assets/images/icons/clock.svg';
import candlewick from '../../assets/images/icons/candlewick.svg';
import fire from '../../assets/images/icons/fire.svg';
import heart from '../../assets/images/icons/heart.svg';
import wood from '../../assets/images/icons/wood.svg';
import {scrollTo} from '../../shared/helpers/scroll-to';
import {MenuLinkId} from '../../shared/enums/menu-link-id';

export const Rules = () => {
    const rules: Rule[] = [
        {
            text: 'Po otrzymaniu świeczki poczekaj aż osiągnie temperaturę pokojową.',
            icon: degrees,
        },
        {
            text: 'Nie pozostawiaj zapalonej świecy bez nadzoru.',
            icon: baby
        },
        {
            text: 'Knot drewniany - może wymagać kilkukrotnego odpalenia, aż knot “złapie” świece.',
            icon: wood
        },
        {
            text: 'Nie pal świecy dłużej niż 4 godziny, zapobiega to powstawaniu zbyt głębokiego “basenu” rozpuszczonego wosku i zatapiania się knota.',
            icon: clock
        },
        {
            text: 'Podczas palenia świecy i zaraz po jej zgaszeniu, nie dotykaj, nie przesuwaj i nie przenoś świeczki. Słoik jest gorący.',
            icon: fire
        },
        {
            text: 'Nie spalaj całej świecy do końca, zapobiegnie to pękaniu słoika od ciepła.',
            icon: heart
        },
        {
            text: 'Knot bawełniany - po każdym paleniu skróć knot do około 0,5 cm długości. Świeca powinna palić się jednorazowo do czasu, aż rozpuści się cała górna powierzchnia wosku.',
            icon: candlewick
        },

    ];
    return (
        <div className="rules" id="rules">
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col-lg-10">
                        <div className="card">
                            <Title text="Zasady palenia"/>
                            <p className="paragraph paragraph--medium paragraph--center">
                                <b>Kilka zasad, aby jak najdłużej cieszyć się ze swojej świeczki</b>
                            </p>

                            <div className="row justify-content-md-center">
                                <div className="col-lg-4">
                                    {rules.map((rule: Rule, index: number) => {
                                        return index < 4 &&
                                            <div className="rules__single" key={index}>
                                                <div className="rules__single-icon">
                                                    <img src={rule.icon}/>
                                                </div>
                                                <p>{rule.text}</p>
                                            </div>
                                    })}
                                </div>
                                <div className="col-lg-4">
                                    {rules.map((rule: Rule, index: number) => {
                                        return index >= 4 &&
                                            <div className="rules__single" key={index}>
                                                <div className="rules__single-icon">
                                                    <img src={rule.icon}/>
                                                </div>
                                                <p>{rule.text}</p>
                                            </div>
                                    })}
                                </div>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-lg-10 rules__button">
                                    <a className="btn btn-outline--brown"
                                       onClick={() => scrollTo(MenuLinkId.Contact)}>Zamów świecę</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
