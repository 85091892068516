import './layout.scss';
import {Description} from '../../../sections/description/description';
import {AboutUs} from '../../../sections/about-us/about-us';
import {ContactForm} from '../../../sections/contact-form/contact-form';
import {Rules} from '../../../sections/rules/rules';
import {useEffect, useState} from 'react';
import {ScentsMobile} from '../../../sections/scents/scents-mobile';
import {Scents} from '../../../sections/scents/scents';
import {Gallery} from '../../../sections/gallery/gallery';

export const Layout = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile: boolean = width <= 1200;

    return (
        <div className="layout">
            <Description/>
            <Rules/>
            <AboutUs/>
            {isMobile ? <ScentsMobile/> : <Scents/>}
            <Gallery/>
            <ContactForm isMobile={isMobile}/>
        </div>
    );
}
