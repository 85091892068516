import './text-image.scss';
import {TextImageProps} from '../../models/text-image';
import {ImagePosition} from '../../enums/position';

export const TextImage = (props: TextImageProps) => {
    return (
        <div className="container">
            <div
                className="row text-image">
                <div className={`col-lg-4 offset-lg-1 ${props.imagePosition === ImagePosition.Right ? 'order-2' : ''}`}>
                    <figure>
                        <img src={props.imageSrc}/>
                    </figure>
                </div>
                <div className={`col-lg-5 offset-lg-1 ${props.imagePosition === ImagePosition.Right ? 'order-1' : ''}`}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}
