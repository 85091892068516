import React, {useEffect, useState} from 'react';
import './toast.scss';
import {ToastProps} from '../../models/toast-props';

const Toast = (props: ToastProps) => {
    const {toastList, position} = props;
    const [list, setList] = useState(toastList);

    useEffect(() => {
        setList([...toastList]);
    }, [toastList]);

    useEffect(() => {
        // const interval = setInterval(() => {
        //     if (toastList.length && list.length) {
        //         deleteToast(toastList[0].id);
        //     }
        // }, 5000);

        // return () => {
        //     clearInterval(interval);
        // }

    }, [toastList, list]);

    const deleteToast = (id: string) => {
        const listItemIndex = list.findIndex(e => e.id === id);
        const toastListItem = toastList.findIndex(e => e.id === id);
        list.splice(listItemIndex, 1);
        toastList.splice(toastListItem, 1);
        setList([...list]);
    }

    return (
        <>
            <div className={`notification-container ${position}`}>
                {
                    list.map((toast, i) =>
                        <div
                            key={i}
                            className={`notification toast ${position}`}
                            style={{backgroundColor: toast.backgroundColor}}
                        >
                            <button onClick={() => deleteToast(toast.id)}>
                                X
                            </button>
                            <div>
                                <p className="notification-title">{toast.title}</p>
                                <p className="notification-message">
                                    {toast.description}
                                </p>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default Toast;
